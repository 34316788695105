<script>
import { required } from "vuelidate/lib/validators";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Modal from "@/components/modals/modal-form.vue";
import api from '@/helpers/apirest/api';

const initialState = () => {
    return {
        submitted: false,
        appToken: {
            expiration: 'infinite',            
            expiration_date: null,            
            description: '',            
        },
        editor: ClassicEditor,
        expirations: [
            {
                id: "one_month",
                label: "1 mes",
            },
            {
                id: "one_year",
                label: "1 año",
            },
            {
                id: "two_years",
                label: "2 años",
            },
            {
                id: "infinite",
                label: "Sin caducidad",
            },
            {
                id: "custom",
                label: "Personalizado",
            },

        ]
    };
}

export default {
    emits: ['onSuccess'],
    props: {
        modalId: String,
        id: Number
    },
    data() {
        return initialState();
    },
    components: {
        Modal,
    },
    computed: {
    },
    validations: {
        appToken: {
            description: { required },
            expiration: { required },
            expiration_date: {  },
        }
    },
    methods: {
        async shown() {
        },
        async confirm() {

            this.submitted = true;
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }

            const data = this.appToken;
            await api.save({
                url: 'app-tokens',
                id: this.appToken?.id,
                data: data,
                config: {
                    withLoading: true
                }
            });

            this.$bvModal.hide(this.modalId);
            this.$emit("onSuccess");
        },
        cancel() {
            this.$bvModal.hide(this.modalId);
        },
        resetModal() {
            Object.assign(this.$data, initialState());
        },
        changeExpiration(){
            if(this.appToken.expiration !== 'custom'){
                this.appToken.expiration_date = null;
            }
        }
    }
};
</script>

<template>
    <Modal :title="'Formulario de integración API'" :id="modalId" @confirm="confirm" @cancel="cancel" @shown="shown" @hidden="resetModal" :size="'lg'">
        <div class="row">

            <div class="col-12 mb-2">
                <div class="card h-100">
                    <div class="card-body">
                        <p class="card-title-desc">
                            Define las caracteristicas del token
                        </p>
                        <div class="row">
                            <div class="form-group col-12">
                                <label for="description">Descripción</label>
                                <p class="text-muted font-13">Asigna una descripción que te permita identificar donde se utiliza este token.</p>
                                <input id="description" v-model="appToken.description" type="text" class="form-control" placeholder="Ingresa una Descripción"
                                    :class="{ 'is-invalid': $v.appToken.description.$error && submitted }" />
                                <div v-if="!$v.appToken.description.required && submitted" class="invalid-feedback">Descripción es requerida</div>
                            </div>
                            <div class="form-group col-12">
                                <label for="slug">Caducidad</label>
                                <select v-model="appToken.expiration" class="form-control" placeholder="Todos" @change="changeExpiration()">
                                    <option value="">Todos</option>
                                    <option v-for="option in expirations" v-bind:value="option.id" :key="option.id">
                                        {{ option.label }}
                                    </option>
                                </select>
                            </div>
                            <div class="form-group col-12" v-if="appToken.expiration === 'custom'">
                                <label for="slug">Fecha de expiración personalizada</label>
                                <input id="expiration_date" v-model="appToken.expiration_date" type="date" class="form-control" placeholder="Ingresa una fecha"
                                    :class="{ 'is-invalid': $v.appToken.expiration_date.$error && submitted }" />
                                <div v-if="$v.appToken.expiration_date.$error && submitted" class="invalid-feedback">Fecha es requerida</div>
                            </div>
                        </div>                        
                    </div>
                </div>
            </div>
        </div>
    </Modal>
</template>