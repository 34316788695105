<script>
import Vue from "vue";
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import AppTokenForm from "./forms/app-token-form.vue";
import AddButton from "@/components/buttons/add-button.vue";
import api from "@/helpers/apirest/api";

/**
 * Stores Component
 */
export default {
  components: {
    Layout,
    PageHeader,
    AddButton,
    AppTokenForm,
  },
  data() {
    return {
      modalId:'app-token-form-modal',      
      intervalId: null,
      pageHeader:{
        title: "Api tokens",
        breadcrumbs: [
          {
            text: "Api tokens",
            href: "/app-tokens",
            active: true
          }
        ],
      },
      integrations: [],
      selectedItemId:null, 
      showSelectedItemId:null,
    };
  },
  methods: {
    showEditModal(item) {
      this.selectedItemId = item?.id || null;
      this.$bvModal.show(this.modalId);
    },
    onSuccess(){
      this.loadData();
    },
    async loadData() {
        try {            
            const response = await api.get({
                url:'app-tokens',
                params:{
                  with_trashed: 1
                }
            });

            const integrations = response?.data?.data;

            integrations.map(item => {
                item.soft_delete_status = (item?.deleted_at) ? 0 : 1;
            });

            this.integrations = integrations;
        } catch (error) {
          this.integrations = [];
        }
    },
    async deleteAppToken(appToken){
        const result = await Vue.swal({
          title: "Eliminar token",
          icon: "info",
          html:  `Está seguro que desea eliminar el token para: ${appToken?.description}?`,
          showCloseButton: false,
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonText: 'Sí',
          cancelButtonText: 'Cancelar',
        });

        if (result.value) {
          await api.delete({
              url: 'app-tokens',
              id: appToken?.id
          });

          this.loadData();
        }        
    },
  },
  mounted() {
    this.loadData();
  },
  beforeDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="pageHeader?.title" :items="pageHeader?.breadcrumbs" />
    <AppTokenForm :modal-id="modalId" :id="selectedItemId" @onSuccess="onSuccess"></AppTokenForm>    
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body d-flex justify-content-between">
            <p class="text-muted font-13">Gestiona los tokens de acceso a la API, para que puedas conectarte desde aplicaciones externas.</p>
            <div class="text-right">
              <AddButton @click="showEditModal"></AddButton>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-4 col-sm-6" v-for="appToken in integrations" :key="appToken.id">
        <div class="card h-100" :class="{'opacity-5': appToken?.deleted_at}">
          <div class="card-body">
            <div class="d-flex align-items-center justify-content-between">
              <h4 class="card-title">
                {{appToken?.description}}
              </h4>
              <b-dropdown right class="qlk-dropdown-sm" variant="white" size="sm">
                <template v-slot:button-content>
                  <i class="mdi mdi-dots-vertical m-0 text-muted font-size-20"></i>
                </template>
                <b-dropdown-item @click="deleteAppToken(appToken)">Eliminar</b-dropdown-item>
              </b-dropdown>
            </div>
            <hr class="my-1" />
            <div class="row">
              <div class="col-12">
                <h6>Token</h6>
                <p class="text-muted mb-2">{{appToken?.plain_text_token}}</p>
              </div>
              <div class="col-12">
                <h6>Caducidad</h6>
                <p class="text-muted mb-2">{{appToken?.personal_access_token?.expires_at || 'Sin caducidad'}}</p>
              </div>
              <div class="col-12">
                <h6>Último uso</h6>
                <p class="text-muted mb-2">{{appToken?.personal_access_token?.last_used_at || 'No usado'}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>